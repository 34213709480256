import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.dizest.modal.create.workflow');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-dizest-modal-create-workflow',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.dizest.modal.create.workflow/view.scss */
.wiz-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: contents;
  width: 100%;
  max-width: 450px;
}
.wiz-modal .modal-content {
  width: 100%;
  min-width: 450px;
  max-width: 450px;
}`],
})
export class PortalDizestModalCreateWorkflowComponent implements OnInit {
    @Input() data = {};
    @Input() config: any;
    @Input() onClose = () => { };

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        this.categories[0] = await this.getCategory("ROOT");
        await this.service.render();
    }

    public categories = [null, null, null];
    public cache = {};
    public async getCategory(categoryId) {
        if (this.cache[categoryId]) return this.cache[categoryId];
        const { code, data } = await this.service.api(`categories/${categoryId}`);
        if (code !== 200) return [];
        const children = data
            .map(({ id, name }) => ({ id: id, name: name, count: 0, open: false }))
            .sort((a, b) => a.name - b.name);
        this.cache[categoryId] = children;
        return this.cache[categoryId];
    }

    public async setCategory(idx, id) {
        await this.service.render();
        if (![1, 2].includes(idx)) return;
        const children = await this.getCategory(id);
        this.categories[2] = null;
        this.categories[idx] = children;
        await this.service.render();
    }

    public async onFileSelected(event) {
        this.service.file.resize(event.target.files[0], 150, 1.0)
            .then(base64String => {
                this.data.image = base64String;
                this.service.render();
            });
    }

    public async create() {
        if (this.data.name.replace(/\s/g, "").length === 0) return;
        const body = this.service.copy(this.data);
        body.category = body.category.findLast(value => value !== null);
        if (!body.category) return;
        if (this.config.active.id === "new") {
            body.position = JSON.stringify(this.config.workflows.active.data.flow);
        }

        const res = await this.config.workflows.create(body);
        if (!res) return;
        this.onClose();
        this.config.workflows.open(res);
    }
}

export default PortalDizestModalCreateWorkflowComponent;