import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.loading');
import { OnInit } from '@angular/core';

@Component({
    selector: 'wiz-component-loading',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/component.loading/view.scss */
/* Missing argument $channels.
  ┌──> src/app/component.loading/view.scss
8 │     background-color: rgba($color: white);
  │                       ^^^^^^^^^^^^^^^^^^^ invocation
  ╵
  ╷
1 │ @function rgba($channels) {
  │           ━━━━━━━━━━━━━━━ declaration
  ╵
  src/app/component.loading/view.scss 8:23  root stylesheet */`],
})
export class ComponentLoadingComponent implements OnInit {

    public async ngOnInit() {}
}

export default ComponentLoadingComponent;