import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.detail');
import { OnInit, DoCheck } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Location } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'wiz-page-detail',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/page.detail/view.scss */
.detail-container .fa-star {
  margin-right: 5px;
  cursor: pointer;
}
.detail-container .fa-star.fa-regular {
  color: goldenrod;
}
.detail-container .fa-star.fa-solid {
  color: gold;
}
.detail-container .tag {
  margin-bottom: 10px;
}
.detail-container .avatar-area .avatar-container .avatar {
  width: 200px;
  height: 200px;
  background: no-repeat;
  background-position: center;
  background-size: contain;
}
.detail-container .detail-go-back {
  display: block;
  font-size: 20px;
  color: #007bff;
  text-decoration: none;
  margin-bottom: 10px;
}
.detail-container .detail-app-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.detail-container .bookmark-details {
  margin-top: 20px;
}
.detail-container .bookmark-details .bookmark-detail-table {
  background-color: #f9f9f9;
  padding-bottom: 15px;
  border-radius: 5px;
  margin-bottom: 10px;
  text-align: center;
}
.detail-container .bookmark-details .bookmark-detail-table h3 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #ffffff;
  background-color: #0054A6;
}
.detail-container .bookmark-details .bookmark-detail-table a {
  display: inline-block;
  width: 200px;
  height: 40px;
  background-color: #ffffff;
  color: #0054A6;
  border: 1px solid #0054A6;
  text-decoration: none;
  border-radius: 5px;
}
.detail-container .bookmark-details .bookmark-detail-table a:hover {
  background-color: #0054A6;
  color: #ffffff;
  border-color: #0054A6;
  text-decoration: underline;
}
.detail-container .detail-details {
  margin-top: 20px;
}
.detail-container .detail-details a.link {
  cursor: pointer;
}
.detail-container .detail-details a.link:hover {
  color: #090909;
}
.detail-container .detail-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}
.detail-container .detail-table th,
.detail-container .detail-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
@media (max-width: 768px) {
  .detail-container .detail-app-header {
    flex-direction: column;
    align-items: flex-start;
  }
  .detail-container .avatar {
    margin-bottom: 10px;
  }
  .detail-container .detail-app-info {
    text-align: left;
  }
}

.related-app {
  width: 400px;
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 20px;
}
.related-app .d-flex {
  gap: 16px;
}
.related-app .multiline-ellipsis {
  line-height: 1.5;
  white-space: normal;
  overflow: hidden;
  font: 14px;
  text-overflow: ellipsis;
}

.multiline-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}`],
})
export class PageDetailComponent implements OnInit, DoCheck {
    public id = "";
    public tab = "";
    public data = null;
    public metaschemas = [];
    public related = [];
    public resource_type = "";

    public groupMap = {
        preprocessing: "전처리",
        model: "모델",
        visualization: "시각화",
        infra: "인프라",
        data: "데이터",
        analyze: "분석",
        etc: "기타",
    };

    public typeMap = {
        str: "String",
        int: "Integer",
    };

    public accessMap = {
        public: "공개",
        internal: "승인 후 사용",
        secret: "비공개",
    };

    constructor(@Inject( Service)         public service: Service,@Inject( Location)         private location: Location,@Inject( ActivatedRoute    )         private route: ActivatedRoute    ) {
        if (!WizRoute.segment.id)
            return service.href("/search");
        this.id = WizRoute.segment.id;
        this.tab = WizRoute.segment.tab;
        this.resource_type = WizRoute.segment.type;
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow();
        // this.route.queryParams.subscribe(params => {
        //     this.tab = params['tab']
        // });
        await this.load();
        await this.service.render();
    }

    public async ngDoCheck() {
        if (!WizRoute.segment.id)
            return this.service.href("/search");
        if (WizRoute.segment.id !== this.id) {
            this.id = WizRoute.segment.id;
            await this.load();
            this.tab = WizRoute.segment.tab;
            this.resource_type = WizRoute.segment.type;
        }
    }

    public async load() {
        const body = { resource_id: this.id, resource_type: this.resource_type };
        await this.service.loading.show();
        let code, data;
        if (this.tab === "local") {
            ({ code, data } = await wiz.call("load", body));
        } else if (this.tab === "hub") {
            ({ code, data } = await wiz.call("resources", body));
        }
        this.data = data;

        await this.service.render();
        if (this.data.resource_type === "workflow") {
            await this.getRelatedApps();
        }
        await this.metaschema();
        await this.viewlog();
        await this.service.loading.hide();
    }

    public async viewlog() {
        const body = { resource_id: this.id, resource_type: this.resource_type, viewer_id: this.service.auth.session.id };
        const { code } = await wiz.call("viewlog", body);
        // if (code !== 200) {
        //     return await this.service.error("카운트 실패");
        // }
    }

    public async metaschema() {
        const { code, data } = await wiz.call("metaschema", { resource_type: this.resource_type });
        this.metaschemas = data ? JSON.parse(data) : [];
    }

    public async bookmark(item) {
        const body = {
            resource_type: item.resource_type,
            resource_id: item.id,
        };
        const { code, data } = await wiz.call("bookmark", body);
        if (code !== 200) return await this.service.error(`북마크 등록 실패!`);
        this.data.bookmark = data;
        await this.service.render();
    }

    public displayName(user) {
        return `${user.first_name} ${user.last_name}(${user.email})`;
    }

    public img(img, size) {
        return {
            backgroundImage: `url(${img})`,
            width: `${size}px`,
            height: `${size}px`,
        }
    }

    public clipboard(url) {
        navigator.clipboard.writeText(url).then(() => {
            this.service.success("다운로드 링크가 복사되었습니다.")
        })
    }

    public link() {
        // type=${this.data.resource_type}&id=${this.id}
        if (this.data.resource_type == "dataset") {
            this.id = '1e6126e8-5b7d-4979-8935-7ea253a9fbf5';
            this.service.app.router.navigate([`/workflow/develop`], {
                queryParams: {
                    type: 'app',
                    id: "1e6126e8-5b7d-4979-8935-7ea253a9fbf5"
                }
            });
        }
        else {
            this.service.app.router.navigate([`/workflow/develop`], {
                queryParams: {
                    type: this.data.resource_type,
                    id: this.id,
                }
            });
        }
    }

    public async getRelatedApps() {
        const appsString = this.data.apps.join(",");

        const body = this.service.copy({ resource_id: appsString });
        this.resource_type = "app";

        if (this.data.org) {
            const { code, data } = await wiz.call("related", body);
            this.related = data;
        } else {
            const { code, data } = await this.service.api("resources/load/related", body);
            this.related = data;
        }
        await this.service.render();
    }
}

export default PageDetailComponent;