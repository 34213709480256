import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.krdc.admin.metadata');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-krdc-admin-metadata',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.krdc.admin.metadata/view.scss */
.header {
  background-image: linear-gradient(108deg, rgba(0, 0, 0, 0.7), transparent), url("/assets/background/private.jpg");
}

.content {
  padding-top: 24px;
}
.content.view-max {
  min-height: 100%;
}
.content.content-light {
  background-color: #E9E8EE;
}

.sticky-menu {
  display: flex;
  align-items: center;
  scrollbar-width: none;
  position: sticky !important;
  z-index: 999999 !important;
}
.sticky-menu .btn {
  height: 45px;
}
.sticky-menu .search-input {
  margin: 0 4px;
}
.sticky-menu .search-input .form-control {
  width: 220px;
  height: 45px;
}

.round-0 {
  border-radius: 0 !important;
}

.content-container {
  position: relative;
  z-index: 0;
}

.card {
  border-radius: 4px;
}
.card.card-info {
  background: #F0F1F3;
  border: none;
  box-shadow: none;
}
.card.card-info .mb-2 {
  display: flex;
}
.card.card-info .card-title {
  font-weight: 1000;
}
.card.card-info i {
  width: 20px;
  text-align: center;
  margin-right: 8px;
  flex: none;
}
.card.card-info .info-title {
  font-weight: 600;
  width: 96px;
  flex: none;
}
.card.card-info .info-content {
  flex: auto;
  text-align: right;
}

.alert h4 {
  font-size: 18px;
  margin-bottom: 12px;
}
.alert ol {
  font-size: 16px;
  line-height: 1.3;
}

h2.title.cursor-pointer:hover {
  color: var(--wc-red);
}

.info-context {
  display: flex;
  margin-bottom: 6px;
  align-items: center;
}
.info-context .form-control,
.info-context .form-select {
  box-shadow: none;
  text-align: left;
}
.info-context:last-child {
  margin-bottom: 0;
}
.info-context .td {
  flex: 1;
}
.info-context .th {
  width: 180px;
  height: 44px;
  display: flex;
  align-items: center;
  font-family: "main-eb";
  padding-right: 12px;
}

.main-container {
  padding: 24px 32px;
  width: 100%;
  max-width: 1160px;
  background-color: #F0F1F3;
  border-radius: 24px;
  margin-bottom: 42px;
}
.main-container .title {
  font-family: SUIT;
  font-size: 24px;
  font-weight: 1000;
  margin-bottom: 16px;
}
.main-container .form-selectgroup-label {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 44px;
  margin-right: 10px;
  box-shadow: none;
}
.main-container .form-control,
.main-container .form-select {
  border-radius: 15px;
  width: auto;
  flex-grow: 1 !important;
  flex: 1;
}
.main-container .tag {
  display: inline-block;
  padding: 0 12px;
}
.main-container .tag > * {
  display: inline-block;
}

.featured-image .featured-text {
  display: none;
}
.featured-image:hover .featured-text {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  font-size: 24px;
}
.featured-image:hover .featured-text i:hover {
  color: var(--wc-red);
}

.info-context img {
  max-width: 320px;
}

.steps-vertical .step-item:not(:first-child) {
  margin-top: 5rem;
}
.steps-vertical .step-item:not(:last-child):after {
  height: calc(100% + 5rem);
}

.steps .step-item {
  cursor: pointer;
}

label.form-check-label {
  cursor: pointer;
}

.row + .row {
  margin-top: 8px;
}

.row .form-label,
.row .form-control {
  height: 40px;
  line-height: 40px;
}
.row .form-check,
.row .form-select {
  height: 40px;
}
.row textarea.form-control {
  min-height: 150px;
  height: auto;
  line-height: 40px;
}

.form-select + .form-select {
  margin-left: 10px;
}

.btn-plus {
  border: 1px dashed var(--tblr-primary);
  color: var(--tblr-primary);
}
.btn-plus:hover {
  background-color: rgba(17, 86, 170, 0.7333333333);
  color: #fff;
  border: 1px solid var(--tblr-primary);
}`],
})
export class PortalKrdcAdminMetadataComponent implements OnInit {

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow("admin", "/main");
        await this.load();
    }

    public data = {
        apps: [],
        workflows: [],
    };

    public async load() {
        const { code, data } = await this.service.api(`metaschemas`);
        if (code !== 200) return [];
        this.data = {
            apps: data.apps ? JSON.parse(data.apps) : [{ id: '', required: false, name: '', type: 'text', description: '', extra: [{ value: '' }] }],
            workflows: data.workflows ? JSON.parse(data.workflows) : [{ id: '', required: false, name: '', type: 'text', description: '', extra: [{ value: '' }] }],
        };
        await this.service.render();
    }

    public async update(type = "apps") {
        let body = JSON.stringify(this.data[type].filter(e => e.name));
        const { code } = await this.service.api(`metaschemas/create/${type}`, { data: body });
        if (code !== 200) return this.service.error("저장 실패. 시스템 관리자에게 문의하세요");
        await this.service.success("저장되었습니다!")
    }

    public async remove(data, target) {
        data.remove(target);
        await this.service.render();
    }
}

export default PortalKrdcAdminMetadataComponent;