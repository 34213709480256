import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.krdc.admin.template.edit');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-krdc-admin-template-edit',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.krdc.admin.template.edit/view.scss */
.header {
  background-image: linear-gradient(108deg, rgba(0, 0, 0, 0.7), transparent), url("/assets/background/private.jpg");
}

.content {
  padding-top: 24px;
}
.content.view-max {
  min-height: 100%;
}
.content.content-light {
  background-color: #E9E8EE;
}

.sticky-menu {
  display: flex;
  align-items: center;
  scrollbar-width: none;
  position: sticky !important;
  z-index: 999999 !important;
}
.sticky-menu .btn {
  height: 45px;
}
.sticky-menu .search-input {
  margin: 0 4px;
}
.sticky-menu .search-input .form-control {
  width: 220px;
  height: 45px;
}

.round-0 {
  border-radius: 0 !important;
}

.content-container {
  position: relative;
  z-index: 0;
}

.card {
  border-radius: 4px;
}
.card.card-info {
  background: #F0F1F3;
  border: none;
  box-shadow: none;
}
.card.card-info .mb-2 {
  display: flex;
}
.card.card-info .card-title {
  font-weight: 1000;
}
.card.card-info i {
  width: 20px;
  text-align: center;
  margin-right: 8px;
  flex: none;
}
.card.card-info .info-title {
  font-weight: 600;
  width: 96px;
  flex: none;
}
.card.card-info .info-content {
  flex: auto;
  text-align: right;
}

.alert h4 {
  font-size: 18px;
  margin-bottom: 12px;
}
.alert ol {
  font-size: 16px;
  line-height: 1.3;
}

h2.title.cursor-pointer:hover {
  color: var(--wc-red);
}

.info-context {
  display: flex;
  margin-bottom: 6px;
  align-items: center;
}
.info-context .form-control,
.info-context .form-select {
  box-shadow: none;
  text-align: left;
}
.info-context:last-child {
  margin-bottom: 0;
}
.info-context .td {
  flex: 1;
}
.info-context .th {
  width: 180px;
  height: 44px;
  display: flex;
  align-items: center;
  font-family: "main-eb";
  padding-right: 12px;
}

.main-container {
  padding: 24px 32px;
  width: 100%;
  max-width: 1160px;
  background-color: #F0F1F3;
  border-radius: 24px;
  margin-bottom: 42px;
}
.main-container .title {
  font-family: SUIT;
  font-size: 24px;
  font-weight: 1000;
  margin-bottom: 16px;
}
.main-container .form-selectgroup-label {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 44px;
  margin-right: 10px;
  box-shadow: none;
}
.main-container .form-control,
.main-container .form-select {
  border-radius: 15px;
  width: auto;
  flex-grow: 1 !important;
  flex: 1;
}
.main-container .tag {
  display: inline-block;
  padding: 0 12px;
}
.main-container .tag > * {
  display: inline-block;
}

.featured-image .featured-text {
  display: none;
}
.featured-image:hover .featured-text {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  font-size: 24px;
}
.featured-image:hover .featured-text i:hover {
  color: var(--wc-red);
}

.info-context img {
  max-width: 320px;
}`],
})
export class PortalKrdcAdminTemplateEditComponent implements OnInit {
    @Input() data: any = {
        name: '',
        type: 'app',
        description: '',
        git_url: '',
    };

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow();
    }

    public async create() {
        let user = this.service.copy(this.data);
        // Keycloak 로그인 호출
        const { code, data } = await wiz.call("signup", user);
        if (code === 200) {
            // Keycloak 로그인 URL로 리다이렉트
            window.location.href = data;
        } else {
            await this.service.error("로그인에 실패했습니다.");
        }
    }

    public async logout() {
        const { code, data } = await wiz.call("logout");
        if (code === 200) {
            window.location.href = data;
        } else {
            await this.service.error("로그아웃에 실패했습니다.");
        }
    }
}

export default PortalKrdcAdminTemplateEditComponent;