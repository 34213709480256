import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.dizest.editor.new');
import { OnInit, OnDestroy } from '@angular/core';
import { Input } from '@angular/core';
import { ViewContainerRef } from '@angular/core';
import { ElementRef, ViewChild } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Drawflow } from "src/libs/portal/dizest/drawflow";
import DrawflowNodeComponent from "src/app/portal.dizest.widget.workflow.node/portal.dizest.widget.workflow.node.component";

@Component({
    selector: 'wiz-portal-dizest-editor-new',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.dizest.editor.new/view.scss */
.dizest-drawflow {
  position: relative;
  flex: 1;
  height: 100%;
  width: 100%;
}
.dizest-drawflow .workflow-zoom {
  position: absolute;
  bottom: 12px;
  right: 12px;
}
.dizest-drawflow .workflow-position {
  width: 100%;
  text-align: center;
  margin-bottom: 6px;
}
.dizest-drawflow .workflow-canvas {
  width: 100%;
  height: 100%;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
}

.workspace-actions {
  top: 24px;
  left: calc(50% - 180px);
  padding: 12px;
  border-radius: 16px;
  background: var(--wc-light-2);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 900;
}
@media (max-width: 768px) {
  .workspace-actions {
    width: 360px;
    left: calc(50% - 180px);
    background: var(--wc-light-3);
  }
}
.workspace-actions .btn {
  margin: 0 6px;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
}
.workspace-actions .kernel {
  width: fit-content;
}
.workspace-actions .spec-header {
  min-width: 120px;
  text-align: center;
  height: 48px;
  margin: 0 6px;
}

.drawflow-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.wiz-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
}
.wiz-modal .modal {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.wiz-modal .modal-dialog {
  max-width: 480px;
  width: 100%;
  height: 60%;
  top: 20%;
  margin: 0 auto;
  overflow: hidden;
}
.wiz-modal .modal-content {
  height: 100%;
  overflow: hidden;
}
.wiz-modal .modal-body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.wiz-modal .modal-body > div {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.wiz-modal .file-title {
  display: flex;
  align-items: center;
}
.wiz-modal .file-title i {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  font-size: 20px;
  color: var(--wc-gray);
}
.wiz-modal .file-title i.fa-folder {
  color: #A1C9F7;
}
.wiz-modal .file-title span {
  cursor: pointer;
}
.wiz-modal .text-muted {
  font-size: 12px;
}
.wiz-modal .select-btns {
  padding-right: 0;
}
.wiz-modal .action-btns {
  padding-left: 4px;
}
.wiz-modal .action-btns,
.wiz-modal .select-btns {
  min-width: 49px;
  white-space: nowrap;
}
.wiz-modal .action-btns .badge,
.wiz-modal .select-btns .badge {
  display: none;
  cursor: pointer;
}
.wiz-modal .action-btns .badge.bg-blue,
.wiz-modal .select-btns .badge.bg-blue {
  display: inline-block;
}
.wiz-modal tr:hover .action-btns .badge,
.wiz-modal tr:hover .select-btns .badge {
  display: inline-block;
  width: 32px;
}

.loading-bar {
  width: 100%;
  height: 10px;
  background-color: #f3f3f3;
  position: relative;
  overflow: hidden;
  margin: 10px 0;
}

.loading-bar .bar {
  width: 50%;
  height: 100%;
  background-color: #3498db;
  position: absolute;
  top: 0;
  left: -100%;
  animation: loading-animation 2s linear infinite;
}

@keyframes loading-animation {
  0% {
    left: -100%;
  }
  50% {
    left: 0%;
  }
  100% {
    left: 100%;
  }
}`],
})
export class PortalDizestEditorNewComponent implements OnInit, OnDestroy {
    @Input() config;
    public DrawflowNodeComponent = DrawflowNodeComponent;

    @ViewChild('drawflow')
    public element: ElementRef;

    public alerts: any = [];
    public phase: string | null = null;
    public isRendered: boolean = false;
    public intervalId: any;

    public workflow: any;
    public drawflow: Drawflow;

    constructor(@Inject( Service)         public service: Service,@Inject( ViewContainerRef    )         public viewContainerRef: ViewContainerRef    ) { }

    public async ngOnInit() {
        this.drawflow = new Drawflow(this);
        await this.service.init();
        await this.init();
        await this.getArgoUrl();
    }

    public async getWorkflowStatus() {
        let workflow_name = this.wfname();
        const body = {
            workflow_name: `${workflow_name}`,
        };
        const { code, data } = await wiz.call("workflow_status", body);
        if (code !== 200) {
            this.phase = "Failed";
        }
        else {
            this.phase = data;
        }

        if (this.phase === "Failed" || this.phase === "Succeeded") {
            try {
                this.logSource.close();
            } catch { }
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
        if (this.phase === "Succeeded") {
            this.phase2 = true;
            setTimeout(() => {
                this.phase2 = false;
                this.service.render();
            }, 1500);
        }
        if (this.phase === "Failed") {
            this.phase3 = true;
            setTimeout(() => {
                this.phase3 = false;
                this.service.render();
            }, 1500);
        }
        await this.service.render();
    }

    public async ngOnDestroy() {
        for (let i = 0; i < this.alerts.length; i++) {
            if (this.alerts[i].alive) {
                await this.alerts[i].hide();
            }
        }
        try {
            clearInterval(this.intervalId);
        } catch { }
    }

    public async init() {
        this.isRendered = false;
        await this.service.render();

        let alert = await this.config.statusbar.alert.info(`initialize workflow: ${this.config.active.name}`);
        this.alerts.push(alert);

        this.workflow = await this.config.dizest.workflow(this.config.active.id);
        this.config.workflows.active = this.workflow;

        await this.workflow.init(this.drawflow);
        this.isRendered = true;
        await this.service.render();
        await this.refresh();

        await alert.hide();
        this.alerts.remove(alert);
        await this.service.render();
    }

    public async showInfo() {
        this.config.activeApp = null;
        await this.service.render();
        this.config.wfinfo = true;
        await this.service.render();
    }

    public async hide() {
        if (this.workflow) {
            this.workflow.destroy();
            delete this.workflow;
        }

        delete this.config.workflows.active;
    }

    public async refresh() {
        if (!this.drawflow) return;
        await this.drawflow.render();
    }

    public isown() {
        try {
            return this.workflow.data.created_by === this.service.auth.session.id;
        } catch {
            return false;
        }
    }

    public status() {
        if (!this.workflow) return '';
        return this.workflow.status;
    }

    public position() {
        if (!this.drawflow) return {};
        return {
            x: this.drawflow.position.x,
            y: this.drawflow.position.y
        }
    }

    public async zoom_out() {
        await this.drawflow.lib.zoom_out();
        await this.service.render();
    }

    public async zoom_in() {
        await this.drawflow.lib.zoom_in();
        await this.service.render();
    }

    public async zoom_reset() {
        await this.drawflow.lib.zoom_reset();
        await this.service.render();
    }

    public async init_pos() {
        await this.drawflow.init_pos();
    }

    public wfname() {
        const wf = this.config.active;
        if (!wf) return;
        const user_id = this.service.auth.session.id;
        if (wf.created_by === user_id)
            return wf.extra.workflow_name;
        else
            return wf.extra[user_id];
    }

    public async run() {
        let workflow_name = this.wfname();
        if (workflow_name) {
            const status = await this.workflow.get_status(workflow_name);
            if (status === "Running") {
                const res = await this.service.alert.show({
                    title: "워크플로우 실행",
                    status: "success",
                    message: "워크플로우가 이미 실행 중입니다. 실행 중인 워크플로우를 중지하고 새로 실행하시겠습니까?",
                    action: "stop & run",
                    actionBtn: "success",
                    cancel: "close",
                });
                if (!res) return;
                await this.workflow.stop(workflow_name);
            }
        }
        try {
            clearInterval(this.intervalId);
        } catch { }
        // await this.service.loading.show();
        workflow_name = await this.workflow.run();
        // await this.service.loading.hide();
        if (!workflow_name) return;
        this.phase = "Running";
        await this.service.render();
        this.watch(workflow_name);
    }

    public async update() {
        let res = await this.workflow.update();
        if (!res) {
            await this.config.statusbar.alert.error('An error occurred while saving', 3000);
            return;
        }
        await this.config.statusbar.alert.info('update workflow', 1000);
    }

    public async stop() {
        const workflow_name = this.wfname();
        if (!workflow_name) return await this.service.error("워크플로우 실행 정보가 존재하지 않습니다.");
        const res = await this.workflow.stop(workflow_name);
        let msg = "워크플로우를 중지 요청하였습니다.";
        if (res.data) msg = res.data;
        await this.service.success(msg);
    }

    public async kill(tabClose: boolean = true) {
        await this.workflow.kill();
        if (tabClose)
            await this.config.close();
    }

    public async dragover($event: any) {
        $event.stopPropagation();
        $event.preventDefault();
    }

    public async drop($event: any) {
        $event.preventDefault();
        let { clientX, clientY } = $event;

        let x = clientX;
        let y = clientY;

        let app_id = $event.dataTransfer.getData("app-id");
        if (!app_id) return;
        await this.workflow.flow.create(app_id, { x: x, y: y, drop: true });
    }

    public isShowDrive: boolean = false;
    public driveTarget: any = null;
    public driveCallback: any = null;
    public driveList: any = [];

    public async driveRequest(fnname: string, query: any = {}) {
        let dizest = this.config.dizest;
        query.workflow_id = this.workflow.id;
        const { code, data } = await dizest.api.call('workflow', `drive/${fnname}`, query);
        return { code, data };
    }

    public async showDrive(value: any, data: any) {
        this.driveTarget = data;
        this.isShowDrive = true;
        let res = await this.driveRequest('list', { path: data ? data : '', scanparent: true });
        res.data.files.sort((a, b) => {
            try {
                if (a.type == b.type)
                    return a.title.localeCompare(b.title);
                if (a.type == 'folder') return -1;
                if (b.type == 'folder') return 1;
            } catch (e) {
            }
        });
        this.driveList = res.data;
        await this.service.render();
        let fn = () => new Promise((resolve) => {
            this.driveCallback = resolve;
        });
        return await fn();
    }

    public async openDrive(item: any) {
        if (item.type != 'folder') {
            this.driveTarget = item.id;
            await this.service.render();
            return;
        }
        let res = await this.driveRequest('list', { path: item.id ? item.id : '' });
        res.data.files.sort((a, b) => {
            try {
                if (a.type == b.type)
                    return a.title.localeCompare(b.title);
                if (a.type == 'folder') return -1;
                if (b.type == 'folder') return 1;
            } catch (e) {
            }
        });
        this.driveList = res.data;
        await this.service.render();
    }

    public async selectDrive(item: any) {
        this.driveTarget = item.id;
        await this.service.render();
    }

    public async selectedDrive() {
        if (this.driveCallback) await this.driveCallback(this.driveTarget);
        this.driveTarget = null;
        this.isShowDrive = false;
        await this.service.render();
    }

    public async hideDrive() {
        this.driveTarget = null;
        this.isShowDrive = false;
        if (this.driveCallback) await this.driveCallback();
        await this.service.render();
    }

    public icon(node: any) {
        if (node.type == 'folder')
            return 'fa-solid fa-folder';
        return 'fa-regular fa-file-lines';
    }

    public argo_base = "";
    public async getArgoUrl() {
        const { code, data } = await wiz.call("argo");
        if (code !== 200) return;
        this.argo_base = data;
    }

    public logSource = null;
    public async watch(workflow_name) {
        try {
            this.intervalId = null;
            this.logSource.close();
        } catch { }
        let url = `${this.argo_base}/${workflow_name}/log?logOptions.container=main&grep=&logOptions.follow=true`;
        this.logSource = new EventSource(url);
        this.logSource.addEventListener("message", e => {
            if (!this.intervalId) {
                this.getWorkflowStatus();
                this.intervalId = setInterval(async () => {
                    await this.getWorkflowStatus();
                }, 1000);
            }
            try {
                const data = JSON.parse(e.data.trim());
                const result = data.result;
                const podName = result.podName;

                let flow = null;
                for (let flow_id in this.config.workflows.active.flow.instances) {
                    const pattern = `${workflow_name}-${flow_id}`;
                    if (podName.startsWith(pattern)) {
                        flow = this.config.workflows.active.flow.instances[flow_id];
                        break;
                    }
                }
                if (!flow) return;
                const spec = flow.spec();
                spec.log += `${result.content}\n`;
                this.service.render();
            } catch (err) {
            }
        });
        this.logSource.addEventListener("error", () => {
            this.logSource.close();
        });
    }
}

export default PortalDizestEditorNewComponent;