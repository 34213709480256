import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.krdc.license.info');
import { OnInit, Input } from '@angular/core';

@Component({
    selector: 'wiz-portal-krdc-license-info',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.krdc.license.info/view.scss */
.license_info {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-right: auto;
}
.license_info thead {
  background-color: #f2f2f2;
  font-weight: bold;
}
.license_info thead th {
  padding: 12px;
  text-align: center;
  border: 1px solid #000000;
}
.license_info tbody td {
  padding: 12px;
  text-align: center;
  border: 1px solid #000000;
}
.license_info tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}
.license_info tbody tr:hover {
  background-color: #f1f1f1;
}`],
})
export class PortalKrdcLicenseInfoComponent implements OnInit {
    @Input() title: any;

    public async ngOnInit() {
    }
}

export default PortalKrdcLicenseInfoComponent;