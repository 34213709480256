import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.krdc.admin.approve');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-krdc-admin-approve',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.krdc.admin.approve/view.scss */
.wiz-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: contents;
  width: 100%;
  max-width: 420px;
}
.wiz-modal .modal-content {
  width: 100%;
  min-width: 280px;
  max-width: 420px;
}

.badge {
  line-height: 20px;
}

textarea {
  resize: none;
}`],
})
export class PortalKrdcAdminApproveComponent implements OnInit {
    public search: any = {
        text: "",
        page: 1,
        dump: 10
    }
    public pagenation: any = {
        total: 0,
        end: -1,
        start: 0,
    }
    public apps = [
        { from_user: null, created_at: new Date().format("yyyy-MM-dd HH:mm:ss"), from_message: null, type: null, data: { id: null, name: null, description: null } }
    ];
    public checked = [];

    constructor(@Inject( Service) public service: Service) { }
    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }

    public async load(page: number = 1) {
        // await this.loading.show();
        if (page === null) page = this.search.page;
        this.search.page = page;
        let body = this.service.copy(this.search);
        const { code, data } = await wiz.call("resources", body);
        // await this.loading.hide();
        if (code !== 200) {
            this.apps = [];
            return await this.service.error("허브 연동 검색에 실패하였습니다.");
        }
        const { rows, lastpage, total } = data;
        this.apps = rows;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        this.pagenation.total = total;
        await this.service.render();
    }

    public async response(res) {
        let body = this.service.copy(this.approve);
        body.status = res;
        const { code, data } = await wiz.call("response", body);
        if (code !== 200) return await this.service.error(res + " 실패했습니다.");
        await this.service.success(res + "성공했습니다.");
        await this.load(this.search.page);
        this.approve = null;
        await this.service.render();
    }

    public approve = null;
    public approveLogs = [];

    public async showApprove(item) {
        this.approveLogs = [];
        this.approve = item;
        this.service.render();
        // const { code, data } = await this.service.api("resources/deploy/log", { user_id: item.data.created_by, resource_id: item.resource_id });
        // if (code === 200) {
        //     this.approveLogs = data;
        //     this.service.render();
        // }
    }
}

export default PortalKrdcAdminApproveComponent;