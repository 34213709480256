import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.krdc.admin.workflows');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-krdc-admin-workflows',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.krdc.admin.workflows/view.scss */
/* src/app/portal.krdc.admin.workflows/view.scss: no such file or directory */`],
})
export class PortalKrdcAdminWorkflowsComponent implements OnInit {
    public search = {
        text: "",
        page: 1,
    }
    public pagenation = {
        end: -1,
        start: 0,
    }
    public list = [];

    constructor(@Inject( Service) public service: Service) { }
    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow("admin", "/main");
        await this.load();
    }

    public async load(page = 1) {
        this.search.page = page;
        await this.service.loading.show();
        const body = this.service.copy(this.search);
        const { code, data } = await wiz.call("load", body);
        if (code !== 200) {
            await this.service.loading.hide();
            return await this.service.error("워크플로우 목록 불러오기 실패!");
        }
        const { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.list = rows;
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        await this.service.loading.hide();
    }

    public async remove(name) {
        await wiz.call("delete", {name});
        await this.load();
    }

    public async clear() {
        await this.service.loading.show();
        await wiz.call("clear");
        await this.service.loading.hide();
        await this.load();
    }
}

export default PortalKrdcAdminWorkflowsComponent;