import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.krdc.workflow.usage');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-krdc-workflow-usage',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.krdc.workflow.usage/view.scss */
.title {
  line-height: 40px;
}`],
})
export class PortalKrdcWorkflowUsageComponent implements OnInit {
    public search: any = {
        text: "",
        page: 1,
        dump: 10
    }
    public pagination: any = {
        end: -1,
        start: 0,
    }
    public list = [];
    public checked = [];

    constructor(@Inject( Service) public service: Service) { }
    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }

    public async load(page = 1) {
        await this.service.loading.show();
        this.search.page = page;
        const body = this.service.copy(this.search);
        const { code, data } = await wiz.call("load", body);
        if (code !== 200) return await this.service.error("Error");
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.list = rows;
        this.pagination.start = startpage;
        this.pagination.end = lastpage;
        await this.service.loading.hide();
        await this.service.render();
    }
}

export default PortalKrdcWorkflowUsageComponent;