import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.krdc.workflow.manage');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { AnsiUp } from 'ansi_up';

@Component({
    selector: 'wiz-portal-krdc-workflow-manage',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.krdc.workflow.manage/view.scss */
.wiz-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: contents;
  width: 100%;
  max-width: 420px;
}
.wiz-modal .modal-content {
  width: 100%;
  min-width: 280px;
  max-width: 420px;
}

.log-list {
  max-height: 600px;
}`],
})
export class PortalKrdcWorkflowManageComponent implements OnInit {
    public ansiUp = new AnsiUp();
    public search = {
        text: "",
        page: 1,
    }
    public pagenation = {
        end: -1,
        start: 0,
    }
    public list = [];

    constructor(@Inject( Service) public service: Service) { }
    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(["developer", "admin"], "/main");
        await this.load();
        await this.getArgoUrl();
    }

    public async load(page = 1) {
        this.search.page = page;
        await this.service.loading.show();
        const body = this.service.copy(this.search);
        const { code, data } = await wiz.call("load", body);
        if (code !== 200) {
            await this.service.loading.hide();
            return await this.service.error("워크플로우 목록 불러오기 실패!");
        }
        const { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.list = rows;
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        await this.service.loading.hide();
    }

    public async remove(name) {
        await wiz.call("delete", { name });
        await this.load();
    }

    public async clear() {
        await this.service.loading.show();
        await wiz.call("clear");
        await this.service.loading.hide();
        await this.load();
    }

    public watch_log = null;
    public logs = [];

    public ansiHTML(txt) {
        return this.ansiUp.ansi_to_html(txt);
    }

    public argo_base = "";
    public async getArgoUrl() {
        const { code, data } = await wiz.call("argo");
        if (code !== 200) return;
        this.argo_base = data;
    }



    public async watch(item) {
        this.logs = [];
        const workflow_name = item.name;
        if (!workflow_name) return;
        this.watch_log = item;
        await this.service.render();
        const source = new EventSource(`${this.argo_base}/${workflow_name}/log?logOptions.container=main&grep=&logOptions.follow=true`);
        source.onmessage = async (e) => {
            try {
                const data = e.data.trim().split("\n")
                    .map(it => {
                        let text = "";
                        try {
                            const obj = JSON.parse(it);
                            text = obj.result.content;
                        } catch { }
                        return text;
                    });
                this.logs.push(...data);
                await this.service.render();
                document.querySelector(".log-list").lastElementChild.scrollIntoView();
            } catch { }
        }
        source.onerror = () => {
            source.close();
        }
    }
}

export default PortalKrdcWorkflowManageComponent;