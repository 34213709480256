import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.service.edit');
import { OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Service } from "src/libs/portal/season/service";
import { ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'wiz-page-service-edit',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/page.service.edit/view.scss */
.row + .row {
  margin-top: 8px;
}

.row .form-label,
.row .form-control {
  height: 40px;
  line-height: 40px;
}
.row .form-check,
.row .form-select {
  height: 40px;
}
.row textarea.form-control {
  min-height: 150px;
  height: auto;
  line-height: 40px;
}`],
})
export class PageServiceEditComponent implements OnInit {
    @Input() title: any;

    constructor(@Inject( Service)         public service: Service,@Inject( Location)         private location: Location,@Inject( ChangeDetectorRef    )         private cdr: ChangeDetectorRef    ) {
        if (!WizRoute.segment.id)
            return service.href("/service/list");
        this.id = WizRoute.segment.id;
    }
    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow();
        await this.load();
        this.categories[0] = await this.getCategory(0);
        if (this.data.category[0]) this.categories[1] = await this.getCategory(1);
        if (this.data.category[1]) this.categories[2] = await this.getCategory(2);
        await this.service.render();
    }

    public view = "config";

    public data = {
        resource_type: "service",
        name: "",
        group: "data",
        // access: "public",
        category: [null, null, null],
        services_org: "",
        keywords: "",
        services_url: "",
    };

    public async load() {
        const body = this.service.copy({ resource_id: this.id });
        const { code, data } = await this.service.api("resources/load", body);
        this.data = data;
        let str = data.category.id
        this.data.category = [null, null, null]
        this.data.category[0] = str[0]
        if (str[1]) this.data.category[1] = str[0] + str[1]
        if (str[2]) this.data.category[2] = str
        await this.service.render();
    }

    public categories = [[], [], []];

    public async getCategory(index) {
        const { code, data } = await this.service.api(`categories/${this.data.category[index - 1] || "ROOT"}`);
        if (code !== 200) return [];
        const category = data;
        return category.sort((a, b) => a.name - b.name);
    }
    public async setCategory(index) {
        this.categories[index] = await this.getCategory(index);
        this.data.category[2] = null;
        await this.service.render();
        if (index == 2) return;
        this.categories[2] = [];
        this.data.category[1] = null;
        await this.service.render();
    }

    public async onFileSelected(event) {
        this.service.file.resize(event.target.files[0], 150, 1.0)
            .then(base64String => {
                this.data.image = base64String;
                this.service.render();
                this.cdr.detectChanges();
            })
    }

    public img() {
        return {
            backgroundImage: `url(${this.data.image})`,
            width: "150px",
            height: "150px",
        }
    }

    public async update() {
        let body = this.service.copy(this.data);
        body.category = body.category.findLast(value => value !== null);
        if (!body.category) return await this.service.error("카테고리를 지정해주세요.");
        delete body.name;
        // body.keywords = JSON.stringify(body.keywords);
        // body.services_org = JSON.stringify(body.services_org);
        // body.services_url = JSON.stringify(body.services_url);
        await this.service.loading.show();
        const { code, data } = await this.service.api("resources/update", body);
        await this.service.loading.hide();
        if (code === 403) return await this.service.error(data);
        if (code !== 200) return await this.service.error("서비스 수정 요청 실패!");
        await this.service.success("서비스 수정 성공!");
        this.service.href("/service/list");
    }




}

export default PageServiceEditComponent;