import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.krdc.admin.total');
import { OnInit, DoCheck, Component, ViewChild, TemplateRef, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import ApexCharts from 'apexcharts';
import moment from 'moment';

@Component({
    selector: 'wiz-portal-krdc-admin-total',
template: templateSource || '',
    styles: [``],
})
export class PortalKrdcAdminTotalComponent implements OnInit {
    appData: number[] = [];
    workflowData: number[] = [];
    datasetData: number[] = [];
    serviceData: number[] = [];
    chartInstance: any;
    dateArray: string[] = [];
    info: any = {};

    @Input() title: any;

    @ViewChild('chart')
    public chartInstance_resource: any;
    public chartInstance_utilize: any;

    public startdate_resource: string;
    public enddate_resource: string;
    public startdate_utilize: string;
    public enddate_utilize: string;


    public info = {
        apps: 0,
        workflow: 0,
        service: 0,

    };

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        this.initializeDates();
        await this.service.auth.allow();
        await this.load();
        this.selecteddata_resource();
        this.selecteddata_utilize();
        await this.apps_count();
    }

    public async initializeDates() {
        const today = moment().format('YYYY-MM-DD');
        this.startdate_resource = moment().subtract(7, 'days').format('YYYY-MM-DD');
        this.enddate_resource = today;
        this.startdate_utilize = moment().subtract(7, 'days').format('YYYY-MM-DD');
        this.enddate_utilize = today;
    }

    private generateDateArray(start: string, end: string): string[] {
        const dates: string[] = [];
        let currentDate = moment(start);

        while (currentDate.isSameOrBefore(end)) {
            dates.push(currentDate.format('YYYY-MM-DD'));
            currentDate = currentDate.add(1, 'days');
        }

        return dates;
    }

    public async load() {
        const { code, data } = await wiz.call("count");
        this.info = data.res;
        await this.service.render();
    }

    public async apps_count() {
        const { code, data } = await wiz.call("apps_count");
        this.info.total_apps_count = data.total_apps_count;
        await this.service.render();
    }

    public async showDeploy() {
        this.deployLogs = [];
        this.deploy = {

        };
        this.service.render();
        if (code === 200) {
            this.deployLogs = data;
            this.service.render();
        }
    }


    public async onStartDateChange_resource() {
        if (moment(this.startdate_resource).isAfter(this.enddate_resource)) {
            this.enddate_resource = this.startdate_resource;
        }
        await this.selecteddata_resource();
    }

    public async onEndDateChange_resource() {
        if (moment(this.enddate_resource).isBefore(this.startdate_resource)) {
            this.startdate_resource = this.enddate_resource;
        }
        await this.selecteddata_resource();
    }

    public async selecteddata_resource() {
        this.dateArray = this.generateDateArray(this.startdate_resource, this.enddate_resource);
        const body = {
            startdate_resource: this.startdate_resource,
            enddate_resource: this.enddate_resource,
        };
        const { code, data } = await wiz.call("chart_data_resource", body);
        this.info["chart"] = data.res;

        this.populateChartData();

        await this.renderChart_resource();
        await this.service.render();
    }

    private populateChartData() {
        this.appData = [];
        this.workflowData = [];
        this.datasetData = [];
        this.serviceData = [];

        this.dateArray.forEach(date => {
            const dateData = this.info.chart.find((item: any) => item.date.startsWith(date)) || { app: 0, workflow: 0, dataset: 0, service: 0 };

            this.appData.push(dateData.app);
            this.workflowData.push(dateData.workflow);
            this.datasetData.push(dateData.dataset);
            this.serviceData.push(dateData.service);
        });
    }

    public async renderChart_resource() {

        if (this.chartInstance_resource) {
            this.chartInstance_resource.destroy();
        }

        let options = {
            series: [
                {
                    name: "앱",
                    data: this.appData
                },
                {
                    name: "워크플로우",
                    data: this.workflowData
                },
                {
                    name: "데이터셋",
                    data: this.datasetData
                },
                {
                    name: "서비스",
                    data: this.serviceData
                }
            ],
            chart: {
                type: "bar",
                height: 350,
                stacked: true,
                toolbar: {
                    show: true
                },
                zoom: {
                    enabled: true
                }
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: "bottom",
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }
            ],
            plotOptions: {
                bar: {
                    horizontal: false
                }
            },
            xaxis: {
                type: "category",
                categories: this.dateArray,
            },
            legend: {
                position: "right",
                offsetY: 40
            },
            fill: {
                opacity: 1
            }
        };


        this.chartInstance_resource = new ApexCharts(document.getElementById('chart_resource'), options);
        await this.chartInstance_resource.render();
    }


    //chart2

    public async onStartDateChange_utilize() {
        if (moment(this.startdate_utilize).isAfter(this.enddate_utilize)) {
            this.enddate_utilize = this.startdate_utilize;
        }
        await this.selecteddata_utilize();
    }

    public async onEndDateChange_utilize() {
        if (moment(this.enddate_utilize).isBefore(this.startdate_utilize)) {
            this.startdate_utilize = this.enddate_utilize;
        }
        await this.selecteddata_utilize();
    }

    public async selecteddata_utilize() {
        this.dateArray = this.generateDateArray(this.startdate_utilize, this.enddate_utilize);
        const body = {
            startdate_utilize: this.startdate_utilize,
            enddate_utilize: this.enddate_utilize,
        };
        const { code, data } = await wiz.call("chart_data_utilize", body);
        this.info["chart"] = data.res;

        this.populateChartData();

        await this.renderChart_utilize();
        await this.service.render();
    }


    public async renderChart_utilize() {

        if (this.chartInstance_utilize) {
            this.chartInstance_utilize.destroy();
        }

        let options = {
            series: [
                {
                    name: "앱 활용",
                    data: this.appData
                },
                {
                    name: "워크플로우 활용",
                    data: this.workflowData
                },
            ],
            chart: {
                type: "bar",
                height: 350,
                stacked: true,
                toolbar: {
                    show: true
                },
                zoom: {
                    enabled: true
                }
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: "bottom",
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }
            ],
            plotOptions: {
                bar: {
                    horizontal: false
                }
            },
            xaxis: {
                type: "category",
                categories: this.dateArray,
            },
            legend: {
                position: "right",
                offsetY: 40
            },
            fill: {
                opacity: 1
            }
        };


        this.chartInstance_utilize = new ApexCharts(document.getElementById('chart_utilize'), options);
        await this.chartInstance_utilize.render();
    }

}

export default PortalKrdcAdminTotalComponent;