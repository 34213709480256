import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.edit');
import { OnInit, DoCheck } from '@angular/core';
import { Location } from '@angular/common';
import { Service } from "src/libs/portal/season/service";
import { ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'wiz-page-edit',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/page.edit/view.scss */
.steps-vertical .step-item:not(:first-child) {
  margin-top: 5rem;
}
.steps-vertical .step-item:not(:last-child):after {
  height: calc(100% + 5rem);
}

.steps .step-item {
  cursor: pointer;
}

label.form-check-label {
  cursor: pointer;
}

.row + .row {
  margin-top: 8px;
}

.row .form-label,
.row .form-control {
  height: 40px;
  line-height: 40px;
}
.row .form-check,
.row .form-select {
  height: 40px;
}
.row textarea.form-control {
  min-height: 150px;
  height: auto;
  line-height: 40px;
}

.form-select + .form-select {
  margin-left: 10px;
}

.btn-plus {
  border: 1px dashed var(--tblr-primary);
  color: var(--tblr-primary);
}
.btn-plus:hover {
  background-color: rgba(17, 86, 170, 0.7333333333);
  color: #fff;
  border: 1px solid var(--tblr-primary);
}

.btn-two-line {
  height: 40px;
}

.wiz-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: contents;
  width: 100%;
  max-width: 1000px;
}
.wiz-modal .modal-content {
  width: 100%;
  min-width: 280px;
  max-width: 1000px;
  max-height: 90vh;
  overflow-y: auto;
}
.wiz-modal .modal-content .wiz-portal-krdc-license-info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-right: auto;
}`],
})
export class PageEditComponent implements OnInit, DoCheck {

    constructor(@Inject( Service)         public service: Service,@Inject( Location)         private location: Location,@Inject( ChangeDetectorRef    )         private cdr: ChangeDetectorRef    ) {
        if (!WizRoute.segment.id)
            return service.href("/app/list");
        this.id = WizRoute.segment.id;
    }

    public async ngDoCheck() {
        if (!WizRoute.segment.id)
            return this.service.href("/app/list");
        this.id = WizRoute.segment.id;
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow();
        await this.loadData();
        await this.load();
        this.categories[0] = await this.getCategory(0);
        if (this.data.category[0]) this.categories[1] = await this.getCategory(1);
        if (this.data.category[1]) this.categories[2] = await this.getCategory(2);
        await this.service.render();
    }

    public async load() {
        const body = this.service.copy({ resource_id: this.id });
        const { code, data } = await this.service.api("resources/load", body);
        try {
            data.template = data.extra.template.name;
        } catch {}
        this.data = data;
        let str = data.category.id
        this.data.category = [null, null, null]
        this.data.category[0] = str[0]
        if (str[1]) this.data.category[1] = str[0] + str[1]
        if (str[2]) this.data.category[2] = str
        await this.service.render();
    }

    public view = "config";
    public useGit = false;
    public git = {
        url: "",
        id: "",
        password: "",
    };
    public data = {
        resource_type: "app",
        name: "",
        group: "preprocessing",
        category: [null, null, null],
        command: "python main.py",
        inputs: [{ name: "", type: "str", description: "", default: "" }],
        outputs: [{ name: "", type: "str", description: "", default: "" }],
        debugs: [{ name: "", type: "str", description: "", default: "" }],
        samples: [{ path: "", description: "" }],
        extra: { meta: {}, }
    };
    public categories = [[], [], []]; // 카테고리 쓸거면 맵핑해서 쓸 것

    public async getCategory(index) {
        const { code, data } = await this.service.api(`categories/${this.data.category[index - 1] || "ROOT"}`);
        if (code !== 200) return [];
        const category = data;
        this.service.render();
        return category.sort((a, b) => a.name - b.name);
    }
    public async setCategory(index) {
        this.categories[index] = await this.getCategory(index);
        this.data.category[2] = null;
        await this.service.render();
        if (index == 2) return;
        this.categories[2] = [];
        this.data.category[1] = null;
        await this.service.render();
    }

    public async onFileSelected(event) {
        this.service.file.resize(event.target.files[0], 150, 1.0)
            .then(base64String => {
                this.data.image = base64String;
                this.service.render();
                this.cdr.detectChanges();
            })
    }

    public img() {
        return {
            backgroundImage: `url(${this.data.image})`,
            width: "150px",
            height: "150px",
        }
    }

    public async update() {
        let body = this.service.copy(this.data);
        delete body.name;
        body.category = body.category.findLast(value => value !== null);
        if (!body.category) return await this.service.error("카테고리를 지정해주세요.");
        body.extra = JSON.stringify(body.extra);
        body.inputs = JSON.stringify(body.inputs.filter(e => e.name));
        body.outputs = JSON.stringify(body.outputs.filter(e => e.name));
        body.debugs = JSON.stringify(body.debugs.filter(e => e.name));
        body.samples = JSON.stringify(body.samples.filter(e => e.path));
        body.git_url = this.git.url;
        body.git_id = this.git.id;
        body.git_password = this.git.password;
        await this.service.loading.show();
        const { code, data } = await this.service.api("resources/update", body);
        await this.service.loading.hide();
        if (code === 403) return await this.service.error(data);
        if (code !== 200) return await this.service.error("앱 수정 요청 실패!");
        await this.service.success("앱 수정 성공!");
        this.service.href("/app/list");
    }

    public config = {
        storage: true,
        gitlab: true,
    };

    public async next() {
        if (!this.config.storage) return await this.service.error("스토리지를 생성해주세요.");
        if (!this.config.gitlab) return await this.service.error("Gitlab 계정을 생성해주세요.");
        const { name } = this.data;
        this.data.name = this.data.name.replace(/\s+/g, " ").trim();
        this.service.render();
        if (name.replace(/\s/g, "").length === 0) return await this.service.error("앱 이름을 입력하세요.");
        if (!/^[A-Za-z0-9_ ]{4,}$/.test(name)) return await this.service.error("앱 이름은 4글자 이상의 알파벳, 숫자, 밑줄(_), 공백으로 이루어져야 합니다.");
        if (!this.data.category[0]) return await this.service.error("카테고리를 지정해주세요.");
        if (!this.data.template) return await this.service.error("템플릿을 지정해주세요.");
        if (Object.keys(this.data.extra.meta).length === 0) return await this.service.error("메타데이터를 지정해주세요.");
        if (this.view == 'account') this.view = 'config';
        else if (this.view == 'config') this.view = 'build';
        await this.service.render();
    }

    public async loadData() {
        const { code, data } = await wiz.call("load");
        if (code !== 200) return this.service.error("템플릿 로드 실패")
        this.templates = data.templates;
        this.metaschemas = data.apps ? JSON.parse(data.apps) : [];
        await this.service.render();
    }

    public async previous() {
        if (this.view == 'build') this.view = 'config';
        else if (this.view == 'config') this.view = 'account';
        await this.service.render();
    }

    public async remove(type, target) {
        this.data[type].remove(target);
        await this.service.render();
    }

    public typeMap(type) {
        switch (type) {
            case "str": return "text";
            case "int": return "number";
            default: return "text";
        }
    }

    public async setTemplate(name: string) {
        let template = this.templates.find(t => t.name === name);
        this.data.extra.template = template;
        if (template) {
            this.git = { url: template.git_url, id: template.git_id, password: template.git_password };
            await this.service.render();
        } else {
            console.error(`Template with name "${name}" not found.`);
        }
    }

    public license = null;

    public async showLicense() {
        this.license = data.license;
        this.service.render();
    }
}

export default PageEditComponent;