import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.krdc.service.list');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { AnsiUp } from 'ansi_up';

@Component({
    selector: 'wiz-portal-krdc-service-list',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.krdc.service.list/view.scss */
table td {
  width: auto;
}

.wiz-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: contents;
  width: 100%;
  max-width: 420px;
}
.wiz-modal .modal-content {
  width: 100%;
  min-width: 280px;
  max-width: 420px;
}`],
})
export class PortalKrdcServiceListComponent implements OnInit {
    public ansiUp = new AnsiUp();
    public search: any = {
        text: "",
        page: 1,
        dump: 10
    }
    public pagination: any = {
        end: -1,
        start: 0,
    }

    public services = [];
    public tab = "local";
    public data = {
        resource_type: "service",
        name: "",
        group: "data",
        category: [null, null, null],
        services_org: "",
        keywords: "",
        services_url: "",
    };

    constructor(@Inject( Service) public service: Service) { }
    public async ngOnInit() {
        await this.service.init();
        await this.load();
        this.ansiUp.use_classes = true;
    }

    public async load(page: number = 1) {
        this.search.page = page;
        const { code, data } = await wiz.call("load", this.search);
        if (code !== 200) return await this.service.error("서비스 조회 실패!");
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.services = rows;
        this.pagination.start = startpage;
        this.pagination.end = lastpage;
        await this.service.render();
    }

    public showChecked() {
        return this.services.filter(it => it.checked).length > 0;
    }


    public remove = null;

    public showRemove(item) {
        if (item.status === "pending") return;
        this.remove = [item];
        this.service.render();
    }

    public async removeItem() {
        const body = {
            resource_type: "service",
            resource_id: this.remove.map(it => it.id).join(","),
        };
        const { code } = await this.service.api("resources/delete", body);
        if (code !== 200) return await this.service.error("서비스 삭제 실패!");
        await this.service.success("서비스 삭제 성공!");
        this.remove = null;
        await this.load(this.search.page);
    }

    public async removeItems() {
        this.remove = this.service.copy(this.services.filter(it => it.checked));
        await this.service.render();
    }



    public deploy = null;
    public access = "internal";
    public log = "";
    public deployLogs = [];

    public async showDeploy(item) {
        if (!["created", "rejected"].includes(item.status)) return;
        this.deployLogs = [];
        this.deploy = item.id;
        this.access = "internal";
        this.log = "";
        this.service.render();
        const { code, data } = await this.service.api("resources/deploy/log", { resource_id: item.id });
        if (code === 200) {
            this.deployLogs = data;
            this.service.render();
        }
    }

    public async deployItem() {
        const body = {
            resource_type: "service",
            resource_id: this.deploy,
            request: this.log,
            access: this.access,
        };
        const { code } = await this.service.api("resources/deploy", body);
        if (code !== 200) return await this.service.error("서비스 배포 요청 실패!");
        await this.service.success("서비스 배포 요청 성공!");
        this.deploy = null;
        this.log = null;
        this.access = null;
        await this.load(this.search.page)
    }


}

export default PortalKrdcServiceListComponent;