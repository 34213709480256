import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.krdc.admin.template');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-krdc-admin-template',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.krdc.admin.template/view.scss */
.wiz-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: contents;
  width: 100%;
  max-width: 420px;
}
.wiz-modal .modal-content {
  width: 100%;
  min-width: 500px;
  max-width: 620px;
}
.wiz-modal .info-context {
  display: flex;
  margin-bottom: 6px;
  align-items: center;
}
.wiz-modal .info-context .form-control,
.wiz-modal .info-context .form-select {
  box-shadow: none;
  text-align: left;
}
.wiz-modal .info-context:last-child {
  margin-bottom: 0;
}
.wiz-modal .info-context .td {
  flex: 1;
}
.wiz-modal .info-context .th {
  width: 180px;
  height: 44px;
  display: flex;
  align-items: center;
  font-family: "main-eb";
  padding-right: 12px;
}`],
})
export class PortalKrdcAdminTemplateComponent implements OnInit {
    public search: any = {
        text: "",
        page: 1,
        dump: 10
    }
    public pagenation: any = {
        end: -1,
        start: 0,
    }
    public templates = [];
    public checked = [];

    constructor(@Inject( Service) public service: Service) { }
    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }

    public async load(page = 1) {
        this.search.page = page;
        await this.service.loading.show();
        const body = this.service.copy(this.search);
        const { code, data } = await wiz.call("templates", body);
        if (code !== 200) {
            await this.service.loading.hide();
            return await this.service.error("템플릿 목록 불러오기 실패!");
        }
        const { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.templates = rows;
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        await this.service.loading.hide();
    }

    public emptyData() {
        return {
            name: '',
            type: 'app',
            description: '',
            git_url: '',
        }
    }
    public modal = {
        mode: null,
        data: this.emptyData(),
    };
    public showModal(mode = null, data = this.emptyData()) {
        this.modal.mode = mode;
        this.modal.data = data;
        this.service.render();
    }
    public async update() {
        const body = this.service.copy(this.modal.data);
        await this.service.loading.show();
        const { code } = await wiz.call("update", body);
        await this.service.loading.hide();
        if (code === 403) {
            return await this.service.error("템플릿 이름이 존재합니다!");
        } else if (code !== 200) {
            return await this.service.error("Error!");
        }
        await this.service.success("템플릿 저장 성공!");
        this.showModal(null);
        await this.load(this.search.page);
    }

    public async remove(item) {
        const res = await this.service.alert.show({
            title: "템플릿 삭제",
            message: `"${item.name}" 템플릿을 정말 삭제하시겠습니까?`,
            action: "삭제",
            status: "error",
            actionBtn: "error",
            cancel: "취소",
        });
        if (!res) return;
        await this.service.loading.show();
        const body = {
            id: item.id,
        };
        const { code } = await wiz.call("delete", body);
        await this.service.loading.hide();
        if (code !== 200) return await this.service.error("Error");
        await this.service.success("템플릿 삭제 성공!");
        await this.load(this.search.page);
    }
}

export default PortalKrdcAdminTemplateComponent;