import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.krdc.admin.list');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-krdc-admin-list',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.krdc.admin.list/view.scss */
.wiz-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: contents;
  width: 100%;
  max-width: 420px;
}
.wiz-modal .modal-content {
  width: 100%;
  min-width: 280px;
  max-width: 420px;
}`],
})
export class PortalKrdcAdminListComponent implements OnInit {
    public search: any = {
        text: "",
        page: 1,
        dump: 10
    }
    public pagenation: any = {
        end: 1,
        start: 1,
        total: 0,
    }
    public apps = [];
    public checked = [];

    constructor(@Inject( Service) public service: Service) { }

    public list = [];

    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }
    public comment = null;

    public async load(page = 1) {
        this.search.page = page;
        this.search.order = "desc";
        this.search.orderby = "created_at";
        const { code, data } = await this.service.api("resources", this.search);
        if (code !== 200) return await this.service.error("조회 실패!");
        let { rows, lastpage, total } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.apps = rows;
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        this.pagenation.total = total
        await this.service.render();
    }

    public async showComment(item) {
        this.comment = item.description;
        this.service.render();
    }


}

export default PortalKrdcAdminListComponent;