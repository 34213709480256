import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.krdc.service.develop');
import { OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Service } from "src/libs/portal/season/service";
import { ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'wiz-portal-krdc-service-develop',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.krdc.service.develop/view.scss */
.row + .row {
  margin-top: 8px;
}

.row .form-label,
.row .form-control {
  height: 40px;
  line-height: 40px;
}
.row .form-check,
.row .form-select {
  height: 40px;
}
.row textarea.form-control {
  min-height: 150px;
  height: auto;
  line-height: 40px;
}`],
})
export class PortalKrdcServiceDevelopComponent implements OnInit {

    constructor(@Inject( Service)         public service: Service,@Inject( Location)         private location: Location,@Inject( ChangeDetectorRef    )         private cdr: ChangeDetectorRef    ) { }
    public async ngOnInit() {
        await this.service.init();
        await this.setCategory(0);
    }

    public useGit = false;
    public git = {
        url: "",
        id: "",
        password: "",
    };
    public data = {
        resource_type: "service",
        name: "",
        group: "data",
        category: [null, null, null],
        services_org: "",
        keywords: "",
        services_url: "",
    };
    public categories = [[], [], []];

    public async getCategory(index) {
        const { code, data } = await this.service.api(`categories/${this.data.category[index - 1] || "ROOT"}`);
        if (code !== 200) return [];
        const category = data;
        return category.sort((a, b) => a.name - b.name);
    }
    public async setCategory(index) {
        this.categories[index] = await this.getCategory(index);
        this.data.category[2] = null;
        await this.service.render();
        if (index == 2) return;
        this.categories[2] = [];
        this.data.category[1] = null;
        await this.service.render();
    }

    public async onFileSelected(event) {
        this.service.file.resize(event.target.files[0], 150, 1.0)
            .then(base64String => {
                this.data.image = base64String;
                this.cdr.detectChanges();
            })
    }

    public async create() {

        let body = this.service.copy(this.data);
        if (body.access === '') {
            body.access = null;
        }
        if (body.name.replace(/\s/g, "").length === 0) return await this.service.error("서비스 이름을 입력하세요.");
        if (!/^[A-Za-z가-힣0-9_ ]{4,}$/.test(body.name)) return await this.service.error("서비스 이름은 4글자 이상의 한글, 알파벳, 숫자, 밑줄(_), 공백으로 이루어져야 합니다.");
        body.category = body.category.findLast(value => value !== null);
        if (!body.category) return await this.service.error("카테고리를 지정해주세요.");
        await this.service.loading.show();
        const { code, data } = await this.service.api("resources/create", body);
        await this.service.loading.hide();
        if (code === 403) return await this.service.error(data);
        if (code !== 200) return await this.service.error("서비스 생성 요청 실패!");
        await this.service.success("서비스 생성 성공!");
        this.service.href("/service/list");
    }

    public config = {
        storage: true,
        gitlab: true,
    };

}

export default PortalKrdcServiceDevelopComponent;