import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.krdc.admin.publish');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-krdc-admin-publish',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.krdc.admin.publish/view.scss */
.card {
  box-shadow: 3px 30px rgba(0, 0, 0, 0.1019607843);
}

.wiz-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: contents;
  width: 100%;
  max-width: 420px;
}
.wiz-modal .modal-content {
  width: 100%;
  min-width: 280px;
  max-width: 420px;
}

.badge {
  line-height: 20px;
}

textarea {
  resize: none;
}`],
})
export class PortalKrdcAdminPublishComponent implements OnInit {
    public search: any = {
        text: "",
        page: 1,
        dump: 10
    }
    public pagination: any = {
        end: -1,
        start: 0,
    }
    public apps = [];
    public checked = [];

    constructor(@Inject( Service) public service: Service) { }
    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }

    public async load(page: number = 1) {
        this.search.page = page;
        const { code, data } = await this.service.api("resources/admin/request", this.search);
        if (code !== 200) return await this.service.error("조회 실패!");
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.apps = rows;
        this.pagination.start = startpage;
        this.pagination.end = lastpage;
        await this.service.render();
    }

    public async accept() {
        await this.service.loading.show();
        const body = this.service.copy(this.deploy);
        body.action = "publish";
        delete body.data;
        const { code } = await this.service.api("resources/admin/request/response", body);
        await this.service.loading.hide();
        if (code !== 200) return await this.service.error("승인 실패!");
        await this.service.success("승인 완료");
        await this.load(this.search.page);
        this.deploy = null;
        await this.service.render();
    }

    public async deny() {
        const body = this.service.copy(this.deploy);
        body.action = "rejected";
        delete body.data;
        const { code } = await this.service.api("resources/admin/request/response", body);
        if (code !== 200) return await this.service.error("반려 실패!");
        await this.service.success("반려 완료");
        await this.load(this.search.page);
        this.deploy = null;
        await this.service.render();
    }

    public deploy = null;
    public deployLogs = [];

    public async showDeploy(item) {
        this.deployLogs = [];
        this.deploy = {
            id: item.id,
            resource_id: item.resource_id,
            resource_type: item.resource_type,
            access: item.data.access,
            response: "",
            data: item.data,
        };
        this.service.render();
        const { code, data } = await this.service.api("resources/deploy/log", { user_id: item.data.created_by, resource_id: item.resource_id });
        if (code === 200) {
            this.deployLogs = data;
            this.service.render();
        }
    }
}

export default PortalKrdcAdminPublishComponent;